// pages/404.jsx
import React, { useEffect } from 'react';
import Link from 'next/link';
import { FaHome } from 'react-icons/fa'; // Importing a home icon from react-icons
import Image from 'next/image'; // Import Image from next/image
import squirrelImage from '../public/sq.png'; // Importing the squirrel image
import styles from '../css/ErrorPage.module.css'; // Ensure you create or update this CSS file

import Navbar from '../components/NavBar';

const ErrorPage = () => {
  useEffect(() => {
    const sendErrorRequest = async () => {
      try {
        const response = await fetch('https://dailycoinmarket.com/error', {
          method: 'POST', // Assuming you need to send a POST request
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ error: '404 Page Not Found' }),
        });

        if (!response.ok) {
          console.error('Failed to send error request');
        }
      } catch (error) {
        console.error('An error occurred while sending the error request', error);
      }
    };

    sendErrorRequest();
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.container}>
        <Image src={squirrelImage} alt="Squirrel" className={styles.squirrelImage} />
        <div className={styles.errorContent}>
          <div className={styles.iconWrapper}>
            <FaHome className={styles.icon} />
          </div>
          <h1 className={styles.title}>404</h1>
          <p className={styles.message}>Sorry, the page you are looking for does not exist.</p>
          <Link href="/" className={styles.homeLink}>Go back to the homepage</Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
